import { Component, NgZone, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { TokenService } from '../../services/token.service';
/* import jwt_decode, { JwtPayload } from 'jwt-decode'; */
import { Router } from '@angular/router';
import { UserService } from '../../users/shared/user.service';
import { CompanyModel, GroupModel } from '../../users/shared/user.model';
import { cwd } from 'process';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-singin-oidc',
  templateUrl: './singin-oidc.component.html'
})

export class SinginOidcComponent implements OnInit
{
  signInTranslate: any;
  companies: GroupModel[] = [];

  constructor(
    public router: Router,
    private tokenService: TokenService,
    private userService: UserService,
    private oidc: OidcSecurityService,
    private translate: TranslateService,
    private ngZone: NgZone
  ) { }


  ngOnInit(): void
  {
    this.signInTranslate = this.translate.instant('auth.signIn');

    this.ngZone.runOutsideAngular(() =>
    {
      this.oidc.checkAuth().subscribe((x) =>
      {
        if (x.isAuthenticated)
        {
          this.tokenService.saveToken(x.accessToken);
          this.redirect();
        }
        else
        {
          this.router.navigate(['unauthorized']);
        }
      });
    });
  }


  setCompany(group: GroupModel)
  {
    this.tokenService.setCompany(group);
    this.redirect();
  }


  redirect()
  {
    // Temp fix until Kiosk role is implmented!
    if (this.tokenService.getUserEmail() == "EHKIOSK@trasolu.dk")
    {
      this.router.navigate(['acr']);
    } else
    {
      this.router.navigate(['start']);
    }
  }

}
